/**
 * Created by mor on 03/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import React from 'react';
import AuthContext from "../../../context/auth-context";
// import PropTypes from 'prop-types';
import {withRouter} from "react-router";

export default (ProtectedComponent) => {
    class AuthConsumer extends React.Component {

        goToLogin = (context, returnPath, eventLabel) => {
            const login = {
                pathname: '/signin',
                state: {
                    context,
                    returnPath,
                    eventLabel
                }
            };

            this.props.history.push(login);
        };
        goToUpdatePassword = (context, returnPath, eventLabel) => {
            console.log('goToUpdatePassword', context, returnPath, eventLabel);
            const profile = {
                pathname: '/profile/password',
                state: {
                    context,
                    returnPath,
                    eventLabel
                }
            };
            
            this.props.history.push(profile);
        };

        render() {
            return (
                <AuthContext.Consumer>
                    {
                        ({ authentication, logOut, authTestDone }) => <ProtectedComponent {...this.props}
                                                                                          goToLogin={this.goToLogin}
                                                                                          goToUpdatePassword={this.goToUpdatePassword}
                                                                                          logOut={logOut}
                                                                                          authenticated={authentication.authenticated}
                                                                                          user={authentication.user}
                                                                                          authTestDone={authTestDone}
                        />
                    }
                </AuthContext.Consumer>
            );
        }
    }

    // AuthConsumer.contextType = AuthContext;

    AuthConsumer.propTypes = {};

    AuthConsumer.defaultProps = {};

    return withRouter(AuthConsumer);
}
