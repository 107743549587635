import UserService from '../../service';
import {ERROR_MESSAGE_TRANSLATE} from "../../../../../common/constants";
import {showSuccessToast, showErrorToast} from '../../../common/toast/actions';
import {showLoading, hideLoading} from 'react-redux-loading-bar';
import * as actionTypes from '../../action-types';



export const updatePassword = ({oldPass, newPass, user, token}) => async (dispatch) => {

    const result = await UserService.updatePassword({oldPass, newPass, userId: user.id, token});
    const {success, data} = result;

    dispatch(showLoading());
    if (success) {
    
            showSuccessToast('הפעולה הושלמה בהצלחה');

          const updatedUser = {
              ...user,
              isPasswordShort:false
          };          
          dispatch({type: actionTypes.UPDATE_IS_PASSWORD_SHORT, payload: updatedUser});
  
            dispatch(hideLoading());
        } else {
            const error = ERROR_MESSAGE_TRANSLATE[data] || 'הסיסמה הנוכחית שהזנת לא נכונה'
            showErrorToast(error);
            dispatch(hideLoading());
        }
    // } else {
    //     showErrorToast('היתה שגיאה במערכת, אנא נסו שנית מאוחר יותר');
    //     dispatch(hideLoading());
    // }
};
export const getUserDetails = () => async (dispatch) => {
    const result = await UserService.getUserDetails();
    const {success, data} = result;
    if (success) {
        dispatch({type: 'PROFILE@USER_DETAILS', payload: data});
    }
}