import React from "react";
import {Link} from 'react-router-dom';
import ValidatedForm from "../../validated-form";
import HelpiButton from "../../helpi-button";
import InputValidator from "../../../common/input-validator";
import FBIcon from '../../../assets/img/login/FACEBOOK.svg';
import {HELPI_BUTTON_CLASSES, EVENTS} from '../../../common/constants';
import GAService from '../../../common/analytics-service';
import HelpiReCaptcha from '../../helpi-recaptcha';
import ReCAPTCHA from "react-google-recaptcha";
import 'formdata-polyfill';

const FormFields = (props) => ({
    email: {
        validator: InputValidator.email,
        type: 'email',
        label: 'אימייל:',
        hint: 'name@company.com',
        klass: 'auth-input',
        validateIcon: <i className={`material-icons`}>check</i>,
        onBlur: ({email: {value}}) => props.checkEmailDomain(value),
        showValidateIcon: props.emailDomainAuthorized,
        onChange: props.resetValidError
    },  company:{
        validator: InputValidator.company,
        type: 'text',
        label: 'חברה:',
        hint: 'בחר חברה',
        klass: 'auth-input'
    },
    password: {
        validator: InputValidator.longPassword,
        type: 'password',
        label: 'סיסמה:',
        hint: 'צריכה להכיל 8 תווים לפחות',
        klass: 'auth-input'
    }
});

class LoginDetailsStage extends React.PureComponent {

    constructor(props){
        super(props);

        this.reCaptcha = React.createRef();

        this.state = {
            valid: false,
            token: null,
            company:null,
            recaptchaToken:null
        };
    }

    componentDidMount() {
        if(window.grecaaptcha !== undefined) {
            this.reCaptcha.current.execute();
        }
    }

    onValidChange = (valid) => this.setState({ valid });

    onLoginDetailsSubmit = async (evt) => {
        evt.preventDefault();

        const form = evt.target;

        const signUpToken = this.state.token;

        const token = await this.reCaptcha.current.getValue();

        if(token) {
          this.setState({ recaptchaToken: token });
            const formData = new FormData(form);

            this.props.onLoginDetailsSubmit({fb: false, email: formData.get('email'), password: formData.get('password'), company: this.state.company, recaptchaToken:token});

            // this.reCaptcha.current.execute();
        }
    };

    handleFieldValueChange = () => {
        if(this.props.validError !== ''){
            this.props.resetValidError();
        }
    };

    onFBSignup = () => {
        this.props.onLoginDetailsSubmit({fb: true, recaptchaToken: this.state.token});
        GAService.event({category: EVENTS.LOGIN_PROCESS.DISPLAY_NAME, action: EVENTS.LOGIN_PROCESS.ACTIONS.FB_CONNECT});
    };

    onGoogleVerify = (recaptchaToken) => this.setState({...this.state, recaptchaToken});

    handleChangeDropdown = (company) => {
        this.setState({company: company});
    };
    onChangeCaptcha = (recaptchaToken) => {

      this.setState({...this.state,  recaptchaToken})
  }
  

    render() {
        const validErrorMessage = this.props.validError ? <div className="error-message">{this.props.validError}</div> : null;

        return (
            <form onSubmit={this.onLoginDetailsSubmit} className="sign-up-form">
                <ValidatedForm
                    onValidChange={this.onValidChange}
                    fields={FormFields(this.props)}
                    handleFieldValueChange={this.handleFieldValueChange}
                    companies={this.props.companies}
                    setOptione={this.handleChangeDropdown}
                    checkEmailDomain={this.props.checkEmailDomain}
                />
                <div className="under-fields">{validErrorMessage}</div>
                <ReCAPTCHA 
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  ref={this.reCaptcha}
                  onChange={this.onChangeCaptcha}
                />
                <HelpiButton disabled={!this.state.valid  || !this.state.recaptchaToken}
                             type={'submit'}
                             label={'המשך'}
                             classList={[HELPI_BUTTON_CLASSES.PRIMARY, HELPI_BUTTON_CLASSES.BIG]}/>
                <div key='divider' className={'divider'}><div>או</div></div>
                <HelpiButton
                    label={"התחברות עם פייסבוק"}
                    icon={FBIcon}
                    classList={[HELPI_BUTTON_CLASSES.PRIMARY, HELPI_BUTTON_CLASSES.FB, HELPI_BUTTON_CLASSES.BIG]}
                    onClick={this.onFBSignup}
                />
                <div className="conditions">* בעצם ההתחברות אני מאשר/ת את <Link to="/terms-and-conditions">תנאי השימוש</Link> <Link to="/privacy-policy">ומדיניות הפרטיות</Link></div>
                {/* <HelpiReCaptcha
                    ref={this.reCaptcha}
                    action="Register"
                    onVerify={this.onGoogleVerify}
                /> */}
            </form>
        );
    }
}

export default LoginDetailsStage;
