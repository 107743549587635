import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';
import InputValidator from '../../common/input-validator';
import ValidatedInput from '../validated-input';
import HelpiButton from "../helpi-button";
import {HELPI_BUTTON_CLASSES} from '../../common/constants';
import './_style.scss';

class ForgotPasswordModal extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            value: '',
            error: '',
            valid: false,
            requestSent: false
        }
    }

    /**
     * handler to sent up email for forget password
     */
    sendRequest = () => this.props.sendRequest(this.state.value);

    /**
     * set state for forgot password modal
     * @param val
     */
    onChange = (val) => {
        const {type} = this.props;
        const error = type === 'request' ? InputValidator.email(val) : InputValidator.longPassword(val);
        const valid = val.length && error === '';
        this.setState({...this.state, value: val, valid});
    };

    onBlur = () => {
        const {type} = this.props;
        const {value} = this.state;
        const error = type === 'request' ? InputValidator.email(value) : InputValidator.longPassword(value);

        this.setState({...this.state, error});


    }

    renderForm = () => {
        const {value, error} = this.state;
        const {type} = this.props;

        const label = type === 'request' ? "כתובת מייל לשחזור סיסמה" : "סיסמה צריכה להכיל 8 תווים לפחות";
        const fieldType = type === 'request' ? 'email' : 'password';
        const buttonLabel = type === 'request' ? 'שחזור סיסמה' : 'סיום';

        return (
            <div>
                <ValidatedInput
                    label={label}
                    type={fieldType}
                    name={"forgot-password"}
                    error={error}
                    onChange={this.onChange}
                    value={value}
                    onBlur={this.onBlur}
                />
                <HelpiButton
                    label={buttonLabel}
                    onClick={this.sendRequest}
                    classList={[HELPI_BUTTON_CLASSES.PRIMARY]}
                    disabled={!this.state.valid}
                />
            </div>
        )
    };

    /**
     * render inputs for forget password modal
     * @returns {XML}
     */
    renderBody = () => {
        const {currState, successMessage} = this.props;

        return !currState.valid ? this.renderForm() : <div className="success-message">{successMessage}</div>;

    };

    handleToggle = () => {
        this.props.handleToggle();
        this.props.resetForgetPassword();
        this.setState({
            value: '',
            error: '',
            valid: false,
            requestSent: false
        })
    };

    render() {
        const {currState, show, successTitle, type} = this.props;

        const form = this.renderBody();

        const title = (!currState.sent && !currState.valid) || !successTitle ? type === 'request' ? 'שחזור סיסמה' : 'בחירת סיסמה חדשה' : successTitle;

        return (
            <div className="forgot-password-modal">
                <Modal
                    show={show}
                    title={title}
                    handleToggle={this.handleToggle}
                    className="forgot-password-modal"
                    render={(props) => <div {...props} className="child-modal">{form}</div>}
                />
            </div>
        )
    }
}

ForgotPasswordModal.propTypes = {
    currState: PropTypes.shape({
        sent: PropTypes.bool,
        valid: PropTypes.bool
    }).isRequired,
    show: PropTypes.bool.isRequired,
    successTitle: PropTypes.string,
    successMessage: PropTypes.string,
    type: PropTypes.oneOf(['request', 'password']),
    handleToggle: PropTypes.func.isRequired,
    resetForgotPassword: PropTypes.func,
};

export default ForgotPasswordModal;