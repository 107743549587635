import {FB_APP_ID} from './constants';
import RestService from './rest-service';

class FBService {

    init = () => {
            window.fbAsyncInit = () => {
                    window.FB.init({
                        appId: FB_APP_ID,
                        cookie: true,  // enable cookies to allow the server to access
                                       // the session
                        xfbml: true,  // parse social plugins on this page
                        version: 'v2.2' // use version 2.2
                    });
                //AuthService.facebookAuthenticator.watchAuthenticationStatusChange();

            };

            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = "//connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
    };

    login = (callback) => window.FB.login((res) => callback(res), {scope: 'email'});

    logout = () => {
        RestService.post('api/logout');     
        window.FB.logout()

    };

}

export default new FBService();