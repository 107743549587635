import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal';
import AuthConsumer from '../../core/containers/auth/auth-protected';
import HelpiButton from '../../components/helpi-button';
import {HELPI_BUTTON_CLASSES, NEWSLETTER_MODAL_SELECT, TASK_TYPE_FILTER, REGION_FILTER} from '../../common/constants';
import MultiSelect from '../../components/multi-select';
import ValidatedInput from '../../components/validated-input';
import InputValidator from '../../common/input-validator';
import HelpiReCaptcha from '../../components/helpi-recaptcha';
import "./style.scss";


class NewsletterModal extends PureComponent {

    constructor(props) {
        super(props);

        this.reCaptcha = React.createRef();

        this.state = {
            firstName: '',
            email: '',
            taskTypes: [],
            regions: [],
            valid: false,
            errors: {
                firstName: '',
                email: ''
            }
        }
    }

    componentDidUpdate(prevProps) {
        if(!prevProps.userDetails && this.props.userDetails) {
            this.setState({...this.state, firstName: this.props.userDetails.firstName, email: this.props.userDetails.email});
        }
    }

    onMultiSelectChange = (name, value) => {
        let values;

        const items = name === 'taskTypes' ? TASK_TYPE_FILTER : REGION_FILTER;

        if (value === '*all*') {
            values = this.state[name].length === items.length ? [] : items.map((item) => item.value);
        } else if (this.state[name].indexOf(value) > -1) {
            values = this.state[name].filter((item) => item !== value);
        } else {
            values = [...this.state[name], value];
        }

        this.setState({...this.state, [name]: values}, this.checkValid);
    };

    onInputChange = (value, evt) => {
        const name = evt.target.name;
        const error = name === 'firstName' ? InputValidator.name(value) : InputValidator.email(value);

        this.setState({...this.state, [name]: value, errors: {...this.state.errors, [name]: error}}, this.checkValid);
    };

    checkValid = () => {
        const {valid, errors, firstName, email, taskTypes, regions} = this.state;

        if(!errors.firstName && !errors.email && firstName.length && email.length && taskTypes.length && regions.length){
            this.setState({...this.state, valid: true});
        } else {
            if (valid) {
                this.setState({...this.state, valid: false});
            }
        }
    };

    generateInputs = () => {
        const {errors} = this.state;

        return (
            <div className="inputs">
                <ValidatedInput
                    name={'email'}
                    label={'כתובת מייל'}
                    error={errors.email}
                    onChange={this.onInputChange}
                    value={this.state.email}
                    type={'email'}
                />
                <ValidatedInput
                    name={'firstName'}
                    label={'שם פרטי'}
                    error={errors.firstName}
                    onChange={this.onInputChange}
                    value={this.state.firstName}
                    type={'text'}
                />
            </div>
        )
    };

    generateForm = () => {

        const {userDetails, errors} = this.props;

        const multiselects = Object.keys(NEWSLETTER_MODAL_SELECT).map((key) => {
            return (
                <MultiSelect
                    data={NEWSLETTER_MODAL_SELECT[key]['data']}
                    name={key}
                    key={`newsletter-multi-select-${key}`}
                    id={`newsletter-multi-select-${key}`}
                    value={this.state[key]}
                    placeholder={NEWSLETTER_MODAL_SELECT[key]['placeholder']}
                    label={NEWSLETTER_MODAL_SELECT[key]['label']}
                    onChange={this.onMultiSelectChange}
                />
            )
        });

        const inputs = userDetails && userDetails.firstName && userDetails.email ? null : this.generateInputs();

        return (
            <div className="child-modal-inner">
                <div className="big-text">רוצה לדעת כשעולה בקשה להתנדבות שמתאימה לך?</div>
                <div className="small-text">ספר/י לנו מה נוח לך ואנחנו נשלח לך את ההצעות במייל</div>
                {multiselects}
                {inputs}
                <HelpiButton
                    label={'שליחה'}
                    disabled={!this.state.valid}
                    classList={[HELPI_BUTTON_CLASSES.PRIMARY]}
                    onClick={this.handleClick}
                />
                <div className="close-modal" onClick={this.handleToggle}>דלג/י</div>
                <div className="errors">{errors}</div>
            </div>
        )

    };

    handleClick = () => {
        const {firstName, email, taskTypes, regions, token} = this.state;
        const payload = {
            firstName,
            email,
            taskTypes,
            regions
        };

        payload['g-recaptcha-response'] = token;

        if(this.props.type === 'update') {
            payload.token = this.props.searchToken;
            this.props.update(payload);
        } else {
            this.props.signUp(payload);
        }

        // this.reCaptcha.current.execute();
    };

    handleToggle = () => {
        this.props.handleToggle();
    };

    generateSuccess = () => {
        return (
            <div className="newsletter-success">
                <div className="big-text">הפרטים נשמרו בהצלחה.</div>
                <div className="small-text">הצעות להתנדבות לפי העדפותיך ישלחו בקרוב לכתובת המייל איתה נרשמת</div>
                <HelpiButton
                    classList={[HELPI_BUTTON_CLASSES.PRIMARY]}
                    label={'חזרה לאתר'}
                    onClick={this.handleToggle}
                />
            </div>
        )
    };

    onReCaptchaVerify = (token) => this.setState({...this.state, token});

    render() {
        const {show, type} = this.props;

        const form = this.generateForm();

        const title = type === 'sign-up' ? 'יש עוד המון הזדמנויות להתנדבות כמו זאת!' : 'עדכון העדפות';

        const success = this.generateSuccess();

        return (
            <div className="newsletter-modal-wrapper">
                {   !this.props.success &&
                    <Modal
                        show={show}
                        title={title}
                        handleToggle={this.handleToggle}
                        render={(props) => <div {...props} className="child-modal">{form}</div>}
                        closeButton={false}
                        className={'newsletter-modal-wrapper'}
                        closeOnOverlayClick={false}
                    />
                }
                {
                    this.props.success &&
                        <Modal
                            show={show}
                            handleToggle={this.handleToggle}
                            render={(props) => <div {...props} className="child-modal">{success}</div>}
                            closeButton={false}
                            className={'newsletter-modal-wrapper'}
                            closeOnOverlayClick={false}
                            showHeader={false}
                        />
                }
                {/* <HelpiReCaptcha
                    ref={this.reCaptcha}
                    action="Newsletter"
                    onVerify={this.onReCaptchaVerify}
                /> */}
            </div>
        )
    }
}

NewsletterModal.propTypes = {
    show: PropTypes.bool
};

export default AuthConsumer(NewsletterModal);