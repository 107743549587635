/**
 * Created by mor on 19/11/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import React, {Component} from 'react';
import AuthConsumer from '../auth/auth-protected';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {CaseShape} from "./shapes";
import * as selectors from "./selectors";
import * as rootSelectors from '../root-reducer/selectors';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as successActions from '../success/actions';
import * as successSelectors from '../success/selectors';
import {SIGN_UP_CONTEXTS} from '../../../common/constants';
import history from '../../../utils/history';

export default (HomepageGui) => {

    const mapStateToProps = (state) => ({
        cases: selectors.selectCasesItems(state),
        count: selectors.selectCasesCount(state),
        loading: selectors.selectCasesLoading(state),
        filters: selectors.selectCasesFilters(state),
        success: selectors.selectCasesSuccesses(state),
        partners: selectors.selectCasesPartners(state),
        newsletterErrors: selectors.selectNewsletterErrors(state),
        newsletterSuccess: selectors.selectNewsletterSuccess(state),
        newsletterUnsubscribeSuccess: selectors.newsletterUnsubscribeSuccess(state),
        subscriber: selectors.selectSubscriber(state),
        whiteLabel: rootSelectors.selectWhiteLabel(state),
        successes: successSelectors.selectSuccesses(state),
        totalSuccesses: successSelectors.selectSuccessTotal(state),
        bannerSuccess: successSelectors.selectBannerSuccess(state),
        emailValidationSuccess: selectors.selectEmailValidationSuccess(state),
        emailValidationDetails: selectors.selectEmailValidationDetails(state),
        emailGenerationSuccess: selectors.selectEmailGenerationSuccess(state),
        finishedHours: selectors.selectFinishedHours(state),
        successesServed: successSelectors.selectSuccessesServed(state)
    });

    const mapDispatchToProps = (dispatch) => ({
        getItems: (filters) => dispatch(actions.getCases(filters)),
        removeCase: (id) => dispatch({ type: actionTypes.REMOVE_CASE, payload: { id } }),
        applyFilter: (filters) => dispatch(actions.applyFilter(filters)),
        newsletterSignUp: (payload) => dispatch(actions.newsletterSignup(payload)),
        clearCases: () => dispatch({ type: actionTypes.CLEAR_CASES}),
        newsletterUpdate: (payload) => dispatch(actions.newsletterUpdate(payload)),
        newsletterUnsubscribe: (token) => dispatch(actions.newsletterUnsubscribe(token)),
        getSubscriber: (token) => dispatch(actions.getSubscriber(token)),
        getSuccesses: (params) => dispatch(successActions.getSuccesses(params)),
        validateEmailToken: (token) => dispatch(actions.validateEmailToken(token)),
        generateEmailToken: (userId) => dispatch(actions.generateEmailToken(userId)),
        getFinishedHours: () => dispatch(actions.getFinishedHours())
    });

    class HomepageWrapper extends Component {

        handleGenerateEmailToken = () => {
            if(!this.props.user) {
                history.navigate({
                    pathname: '/signin',
                    state: {
                        context: SIGN_UP_CONTEXTS.GENERATE_EMAIL,
                    }
                })
            } else {
                this.props.generateEmailToken(this.props.user.id);
            }
        }
        componentDidUpdate() {
            if (this.props.user && this.props.user.isPasswordShort) {
                this.props.goToUpdatePassword(null, '/profile');
            }

        }

        render() {
            return (
                <HomepageGui cases={this.props.cases}
                             count={this.props.count}
                             removeCase={this.props.removeCase}
                             applyFilter={this.props.applyFilter}
                             getItems={this.props.getItems}
                             loading={this.props.loading}
                             params={this.props.params}
                             filters={this.props.filters}
                             partners={this.props.partners}
                             success={this.props.success}
                             newsletterErrors={this.props.newsletterErrors}
                             newsletterSuccess={this.props.newsletterSuccess}
                             newsletterSignUp={this.props.newsletterSignUp}
                             newsletterUnsubscribe={this.props.newsletterUnsubscribe}
                             newsletterUpdate={this.props.newsletterUpdate}
                             newsletterUnsubscribeSuccess={this.props.newsletterUnsubscribeSuccess}
                             getSubscriber={this.props.getSubscriber}
                             subscriber={this.props.subscriber}
                             clearCases={this.props.clearCases}
                             location={this.props.location}
                             user={this.props.user}
                             authTestDone={this.props.authTestDone}
                             whiteLabel={this.props.whiteLabel}
                             getSuccesses={this.props.getSuccesses}
                             successes={this.props.successes}
                             totalSuccesses={this.props.totalSuccesses}
                             bannerSuccess={this.props.bannerSuccess}
                             finishedHours={this.props.finishedHours}
                             getFinishedHours={this.props.getFinishedHours}
                             validateEmailToken={this.props.validateEmailToken}
                             generateEmailToken={this.handleGenerateEmailToken}
                             emailValidationSuccess={this.props.emailValidationSuccess}
                             emailValidationDetails={this.props.emailValidationDetails}
                             emailGenerationSuccess={this.props.emailGenerationSuccess}
                             successesServed={this.props.successesServed}
                             goToUpdatePassword={this.goToUpdatePassword}

                />
            );
        }
    }

    HomepageWrapper.propTypes = {
        items: PropTypes.arrayOf(CaseShape),
        removeCase: PropTypes.func.isRequired,
        applyFilter: PropTypes.func.isRequired,
        getItems: PropTypes.func.isRequired,
    };

    return withRouter(AuthConsumer(connect(mapStateToProps, mapDispatchToProps)(HomepageWrapper)));
};
