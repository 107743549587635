/**
 * Created by mor on 04/12/2018.
 *
 * Decor Advanced Web Solutions
 * www.decor-d.com
 *
 * File description:
 */
import validator from 'validator';
import {COMMON_PASSWORDS} from './constants';
import isIsraeliIdValid from 'israeli-id-validator';

class InputValidator {
    password = (value) => {
        if (!validator.isLength(value, { min: 6 })) {
            return 'סיסמה קצרה מדי';
        }

        if (COMMON_PASSWORDS.indexOf(value) > -1) {
            return 'הסיסמה נפוצה מדי, יש לבחור סיסמה אחרת';
        }

        return '';
    };
    longPassword = (value) => {
         if (!validator.isLength(value, { min: 8 })) {
            return 'סיסמה קצרה מדי';    

         }
         if (COMMON_PASSWORDS.indexOf(value) > -1) {
            return 'הסיסמה נפוצה מדי, יש לבחור סיסמה אחרת';
            
         }

        return '';
    }
    checkEmailSuffix = (value)=>{
        const parts = value.split('.');
        const lastPart = parts[parts.length - 1].toLowerCase();
        if(lastPart == 'cm' || lastPart == 'con'){
            return false;
        }else{
            return true;
        }
    }

    passwordLogin = (value) => validator.isLength(value, { min: 1 }) ? '' : 'סיסמה קצרה מדי';

    email = (value) => validator.isEmail(value) && this.checkEmailSuffix(value) ? '' : 'אימייל לא תקין';

    company = (value ) => value ? '' : 'אנא בחר חברה';

    emailRequired = (value) => validator.isEmail(value) &&  validator.isLength(value, { min: 1 }) ? '' : 'אימייל לא תקין';

    name = (value) => validator.isLength(value, { min: 2 }) && value.match(/^[a-zA-Zא-ת ]+$/) ? '' : 'שם לא תקין';

    phone = (value) => validator.isMobilePhone(value, "he-IL") &&  validator.isLength(value, { min: 10 }) ? '' : 'אנא הזן טלפון סלולרי תקין';

    intRequiredPositive = (value) => validator.isInt(value) && validator.isLength(value, { min: 1 }) && value > 0 ? '' : 'מספר לא תקין';

    intRequiredPositiveMultiple = (value) => validator.isInt(value) && validator.isLength(value, { min: 1 }) && value > 1 ? '' : 'מספר לא תקין';

    phoneRequestForm = (value) => (validator.isMobilePhone(value, "he-IL") &&  validator.isLength(value, { min: 10 })) || validator.isLength(value, { max: 0 }) ? '' : 'אנא הזן טלפון סלולרי תקין';

    emailRequestForm = (value) => validator.isEmail(value) || validator.isLength(value, { max: 0 }) ? '' : 'כתובת המייל אינה תקינה';

    id = (value) => isIsraeliIdValid(value) && validator.isLength(value, { min: 8 }) ? '' : 'אנא הזן תעודת זהות תקינה';

    any = (value) => '';
}

export default new InputValidator();
